import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import UniversalLink from "./UniversalLink"
import styled from "styled-components"

const NavItem = styled.div`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.5pt;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #cd2653;
    height: 1px;
    transition: all 0.2s ease-in;
  }
  :hover {
    color: #cd2653;
    ::after {
      width: 100%;
    }
  }
  :focus {
    color: #cd2653;
  }
  @media (max-width: 997px) {
    padding: 10px 0;
    z-index: 6;
    border-bottom: 1px solid lightgrey;
    width: 70%;
    text-align: center;
  }
`
const Drop = styled.div`
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.5pt;
  cursor: pointer;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #fff;
    height: 1px;
    transition: all 0.2s ease-in;
  }
  @media (max-width: 997px) {
    padding: 10px 0;
    font-size: 15px;
    z-index: 6;
    border-bottom: 1px solid lightgrey;
    width: 70%;
    text-align: center;
    color: #0f0f0f;
    :hover {
      ::after {
        width: 0%;
      }
    }
  }
  .down {
    width: 6.5pt;
    line-height: 9.5pt;
    vertical-align: text-top;
    @media (max-width: 997px) {
      vertical-align: sub;
      color: #5e3994;
    }
  }
`
const DropList = styled.ul`
  display: none;
  list-style: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.9) !important;
  padding: .1em 0 0;
  margin-top: 0;
  top: 37px !important;
  margin-left: .8em;
  box-shadow: 0px 2px 3px 0px #e2e2e2;
  right: 0 !important;
  opacity: 1 !important;
  width: 150px;
  li {
    margin: 1em .5em;
    text-align: left;
    :after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 0%;
      content: ".";
      color: transparent;
      background: #1e4183;
      height: 1px;
      transition: all 0.2s ease-in;
    }
    :hover {
      color: #1e4183;
      ::after {
        width: 100%;
      }
    }
    :focus {
      color: #1e4183;
    }
    a {
      margin: 0;
      color: #0f0f0f;
      text-transform: none;
      padding: 0;
      &:hover {
        color: #5e3994;
      }
      @media (max-width: 997px) {
        padding: 5px 0;
        font-size: 14px;
      }
    }
  }
  .parent {
    border-bottom: none;
  }
  ::before {
    content: unset !important;
  }
  @media (max-width: 997px) {
    padding: 0;
    width: 70%;
    margin: auto;
    text-align: center;
    position: relative;
    display: contents;
    li {
      padding: 0;
      a {
        text-transform: unset;
        padding: 7px 0;
        width: 50%;
      }
    }
  }
  .all {
    border-top: 1px solid #5e3994;
    @media (max-width: 997px) {
      border: none;
    }
    a {
      color: #5e3994;
      font-weight: bold;
    }
  }
`
const Submenu = styled.div`
  @media (min-width: 997px) {
    &:hover {
      .parent {
        ::after {
          width: 100%;
        }
      }
      .List {
        display: block;
        a::after {
          background: linear-gradient(90deg, #5e3994 30%, #fff 100%);
        }
      }
    }
  }
  @media (max-width: 997px) {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    .List li {
      text-align: center;
      a {
        margin: auto;
      }
    }
  }
`
const Menu = () => {
  const { wpMenu, allWpCptKasino } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "primary" }) {
        name
        menuItems {
          nodes {
            label
            url
            databaseId
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                }
              }
            }
          }
        }
      }
      allWpCptKasino(filter: {slug: {in: []}}) {
        nodes {
          id
          uri
          slug
          title
        }
      }
    }
  `)

  if (!wpMenu?.menuItems?.nodes || wpMenu.menuItems.nodes === 0) return null

  return (
    <nav
      className="primary-menu-wrapper"
      aria-label="Horizontal"
      role="navigation"
    >
      <div className="primary-menu reset-list-style">
        {wpMenu.menuItems.nodes.map((menuItem, i) => {
          const path = menuItem?.connectedNode?.node?.uri ?? menuItem.url
          const itemId = "menu-item-" + menuItem.databaseId
          return (
            <NavItem
              id={itemId}
              key={i + menuItem.url}
              className={
                "menu-item menu-item-type-custom menu-item-object-custom menu-item-home " +
                itemId
              }
            >
              <UniversalLink
                to={path}
                activeClassName={"current-menu-item current_page_item"}
              >
                {menuItem.label}
              </UniversalLink>
            </NavItem>
          )
        })}
      </div>
    </nav>
  )
}

export default Menu
